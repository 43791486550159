// 审核-中间层
<template>
  <div>
    <!-- {{examineType}} -->
    <!-- 普通审核制作 -->
    <signing-management-review v-if="examineType==='SIGNATURE_SIGN' || examineType==='SIGNATURE'"></signing-management-review>
    <review-meeting v-else></review-meeting>
  </div>
</template>
<script>
import ReviewMeeting from './audit-type/review-meeting.vue'
import Storage from '@/utils/storage'
import signingManagementReview from './audit-type/signing-management-review.vue'
export default {
  components: { ReviewMeeting, signingManagementReview },
  data () {
    return {
      businessId: '', // 业务id
      baseData: {}, // 基础数据
      examineType: Storage.getSession('examine').finBusuinessInfoVO.statusCode // 审核业务环节
    }
  },
  computed: {
    type () {
      return this.$route.query.type
    }
  },
  created () {
    this.businessId = this.$route.query.businessId
  },
  mounted () {
    console.log(this.examineType)
  },
  watch: {
  },
  methods: {
    // 获取基本信息

  }
}
</script>
