<template>
<!-- 所有审核 -->
  <div class="info">
    <backTitle></backTitle>
    <div></div>
    <auditdetails
      :filelist="filelist"
      :title="title"
      :titlecomfig="titlecomfig"
      :finReviewOpinionInfoVOS="finReviewOpinionInfoVO"
      :showStep="this.$route.query.showStep?false:true"
      :processStatus="processStatus"
      style="margin-top: 15px;height: calc(100% - 58px);"
      :type='type'
      @fileUpdate='fileUpdate'
      :openBackToProcess='openBackToProcess'
    >
    <!-- 修改按钮 -->
    <template v-slot:titleRight>
      <base-button label="修改" @click="jump"></base-button>
    </template>
    </auditdetails>
  </div>
</template>
<script>
import { getDict } from '@/filters/fromDict'
import backTitle from '@/pages/business/components/backtitle.vue'
import auditdetails from '@/pages/business/components/auditdetails.vue'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import Storage from '@/utils/storage'
import { getManager } from '@/api/sys/user'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { getUrlParams } from '@/utils/devUtils'
export default {
  name: 'ImplementAudit',
  components: { auditdetails, backTitle, BaseButton },
  data () {
    return {
      processStatus: Storage.getSession('examine').finBusuinessInfoVO.statusCode,
      openBackToProcess: false, // 是否显示回退流程
      type: this.$route.query.type,
      finReviewOpinionInfoVO: [],
      title: '供应商：',
      titlecomfig: [
        {
          label: '业务编号：',
          value: ''
        },
        {
          label: '盖章方式：',
          value: '电子章'
        }
      ],
      activeid: '',
      filelist: [
        { label: '合同文件', key: 'htwj', child: [], id: '1' },
        { label: '内部资料', key: 'nbzl', child: [], id: '2' },
        { label: '客户资料', key: 'khzl', child: [], id: '3' },
        { label: '担保人资料', key: 'dbrzl', child: [], id: '4' },
        { label: '标的资产相关', key: 'bdzcxg', child: [], id: '5' },
        { label: '中登资料文件', key: 'zdzl', child: [], id: '6' },
        { label: '要素落实资料', key: 'ysls', child: [], id: '7' },
        { label: '其他资料', key: 'qtzl', child: [], id: '8' },
        { label: '融资申请资料', key: 'fjxi', child: [], id: '9' },
        { label: '尽调资料', key: 'jdbg', child: [], id: '10' },
        { label: '评审会批复资料', key: 'pshpf', child: [], id: '11' },
        { label: '评审会落实资料', key: 'pshls', child: [], id: '12' },
        { label: '融资申请表', key: 'ywspb', child: [], id: '13' },
        { label: '融资决议资料', key: 'rzjy', child: [], id: '14' },
        { label: '存续期资料', key: 'cxqzl', child: [], id: '15' },
        { label: '基础资料', key: 'jczl', child: [], id: '16' }
      ],
      activeNames: '1'
    }
  },
  computed: {
    // api () {
    //   return financeAdmittanceApi
    // }
  },
  methods: {
    // 合同文件审核,上传成功,刷新页面
    fileUpdate () {
      this.getdetail()
    },
    jump () {
      const data = JSON.parse(JSON.stringify(Storage.getSession('examine').finBusuinessInfoVO))
      let jump = false
      // "评审会落实" type=1
      if (data.statusCode === 'SUPPLEMENT') {
        this.$set(data, 'type', '1')
        this.$set(data, 'isExamineData', true)
        Storage.setLocal('contractInfo', data)
        jump = true
      }
      // ""评审会批复"" type=2
      if (data.statusCode === 'REVIEW') {
        this.$set(data, 'type', '2')
        this.$set(data, 'isExamineData', true)
        Storage.setLocal('contractInfo', data)
        jump = true
      }
      if (jump) {
        this.$router.push({
          path: '/business/approvalProduction',
          query: { businessNo: data.businessNo }
        })
      }
    },
    // 风控经理riskManager
    getRiskManagerOptions () {
      getManager({ roleCode: 'fkjl_code' }).then(res => {
        this.riskManagerOptions = res.data.map(item => {
          return { riskManagerId: item.userId, riskManagerName: item.nickName, riskUserName: item.userName }
        })
      })
      return this.riskManagerOptions
    },
    handleClick () {},
    getdetail () {
      const listData = Storage.getSession('examine').finBusuinessInfoVO
      const params = {
        businessId: listData.keyId,
        processEnum: listData.statusCode,
        batchNum: listData.batchNum || null,
        instanceId: getUrlParams(location.href)?.instanceId || null
      }
      financeAdmittanceApi.approvalHandle(params).then((res) => {
        console.log(res)
        this.title = this.title + listData.gysCompanyName
        this.titlecomfig[0].value = listData.businessNo
        this.titlecomfig[1].value = getDict('SUPPLIERS_SEAL_TYPE', listData.suppliersSealType.toString())
        if (res.data.finFileListInfoVO) {
          res.data.finFileListInfoVO.forEach((item) => {
            this.filelist.forEach((elem) => {
              if (item.fileGroupCode === elem.key) {
                elem.child.push(item)
              }
            })
          })
        } else if (res.data.fileListInfoVOS) {
          res.data.fileListInfoVOS.forEach((item) => {
            this.filelist.forEach((elem) => {
              if (item.fileGroupCode === elem.key) {
                elem.child.push(item)
              }
            })
          })
        }
        this.finReviewOpinionInfoVO = res.data.finReviewOpinionInfoVO
      })
    }
  },
  created () {
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    this.getdetail()
  }
}
</script>
<style lang="scss" scoped>
.info{
  height: 100%;
}
/deep/ .el-tabs--border-card {
  box-shadow: none;
  border: 0;
}

</style>
